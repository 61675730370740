<template>
  <div class="mt-2">
    <!-- <div v-for="(temp, index) in getCustomerTemplate" :key="index">
      <component
        :is="`Template${temp.template_id}`"
        :data="getData(temp.template_fields)"
        :showDelete=showDeleteIcon
        :template_id="temp.customer_template_id"
      />
    </div> -->

    <div
      class="training-language-course mx-auto position-relative text-center"
      style="max-width: 1154px"
    >
      <div
        class="d-flex justify-content-between align-items-center mb-3 mx-lg-5"
      >
        <CButton color="primary" v-if="getFilter">
          {{ getFilter }}
        </CButton>
        <CButton color="primary" to="/tfw/partner/profile">Back</CButton>
      </div>

      <img
        v-if="getImg"
        ref="myImage"
        :src="getImg"
        class="img-fluid"
        style="max-width: 1154px"
        @click="onImageClick"
      />
    </div>
  </div>
</template>
<script>
import * as _ from "lodash";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { CONSTANT } from "@/helpers/helper";
import Template1 from "../../containers/CourseTemplate/Template-1.vue";
import Template2 from "../../containers/CourseTemplate/Template-2.vue";
import Template3 from "../../containers/CourseTemplate/Template-3.vue";
import Template4 from "../../containers/CourseTemplate/Template-4.vue";

Vue.component("Template1", Template1);
Vue.component("Template2", Template2);
Vue.component("Template3", Template3);
Vue.component("Template4", Template4);

export default {
  data() {
    return {
      show_delete: false,
      items: CONSTANT.partnerProfiles,
    };
  },
  computed: {
    ...mapGetters(["getCustomerTemplate"]),
    showDeleteIcon() {
      return this.$route.path.includes("/partner/profile/view/") ? false : true;
    },
    getItemById() {
      const id = this.$route.params.id;
      return this.items.find((item) => item.id == id);
    },
    getImg() {
      const item = this.getItemById;
      return item ? item.view_image : null;
    },
    getFilter() {
      const item = this.getItemById;
      return item ? item.filter_names : null;
    },
  },
  methods: {
    ...mapActions(["fetchCustomerTemplate"]),
    getData(data) {
      const convertedObject = data.reduce(
        (acc, { field_name, field_value }) => {
          acc[field_name] = field_value;
          return acc;
        },
        {}
      );
      return convertedObject;
    },
    onImageClick(event) {
      const item = this.getItemById;
      const image = this.$refs.myImage;
      const boundingRect = image.getBoundingClientRect();
      const xPosition = event.clientX - boundingRect.left;
      const yPosition = event.clientY - boundingRect.top;
      // To find coordinates
      // console.log("xPosition: ", xPosition);
      // console.log("yPosition: ", yPosition);

      if (item.btnPosition) {
        const clickedButton = item.btnPosition.find((v, index) => {
          const { x, y } = v;
          const xCond = x.start <= xPosition && x.end >= xPosition;
          const yCond = y.start <= yPosition && y.end >= yPosition;
          return xCond && yCond;
        });

        if (clickedButton) {
          const index = item.btnPosition.indexOf(clickedButton);
          const url = item.urls[index];

          if (url) {
            window.open(url, "_blank");
          }
        }
      }
    },
  },
  async mounted() {
    let customer_id = this.$route.params.customer_id || null;
    let org_id = this.$route.params.org_id || null;

    if (customer_id && org_id) {
      await this.fetchCustomerTemplate({ customer_id, org_id });
    }
  },
};
</script>
